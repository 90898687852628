import "./App.css";
import "./Reset.css";
import React, {useState,useEffect} from "react";


export default (props) => {

    useEffect(() => {
        window.location.href = "https://www.black-belt.io/offres-d-emploi"
    }, []);

  return (<></>);
}




